import React, { type FC } from 'react';

import { Box } from '@atlaskit/primitives';
import { FlagsProvider } from '@atlaskit/flag';

import {
	SmartsFeedbackComponent,
	FeedbackCollectorPropertiesProvider,
} from '@confluence/quick-summary';

import { useIssueCreateSidePanelContext } from './providers/IssueCreateSidePanelContextProvider';

const FEEDBACK_ENTRYPOINT_ID = '5dd040e8-af22-4ef1-803e-136f659a1092';
const SOURCE = 'confluenceHighlightIssueCreate';

type IssueCreateAiFeedbackProps = {
	contentId: string;
};

export const IssueCreateAiFeedback: FC<IssueCreateAiFeedbackProps> = ({ contentId }) => {
	const [{ aiState, aiAnalyticsContext }] = useIssueCreateSidePanelContext();
	const shouldShowFeedbackCollector = aiState !== 'loading';
	const additionalAnalyticsContext = { additionalAnalyticsAttributes: aiAnalyticsContext };
	return shouldShowFeedbackCollector ? (
		<FlagsProvider>
			<FeedbackCollectorPropertiesProvider entrypointId={FEEDBACK_ENTRYPOINT_ID}>
				<Box padding="space.100">
					<SmartsFeedbackComponent
						analyticsProperties={{
							source: SOURCE,
							...additionalAnalyticsContext,
						}}
						contentId={contentId}
					/>
				</Box>
			</FeedbackCollectorPropertiesProvider>
		</FlagsProvider>
	) : null;
};
