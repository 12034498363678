import { useEffect, useState } from 'react';

import type { FetchProjectDataProps, JiraProjects } from '../__types__/apiUtils';

import { fetchJiraProjects, fetchRecentJiraProjects } from './apiUtils';

export const useGetJiraProjects = ({ cloudId, query, onComplete }: FetchProjectDataProps) => {
	const [recentProjects, setRecentProjects] = useState<JiraProjects | undefined>(undefined);
	const [projects, setProjects] = useState<JiraProjects | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);
	useEffect(() => {
		if (!cloudId) {
			setRecentProjects(undefined);
			setProjects(undefined);
			return;
		}
		const fetchProjects = async () => {
			setLoading(true);
			try {
				if (query) {
					const projectResult = await fetchJiraProjects({ cloudId, query });
					handleProjectData({ status: 'fulfilled', value: projectResult });
					onComplete?.(undefined, projectResult);
				} else {
					const [recentProjectResult, projectResult] = await Promise.allSettled([
						fetchRecentJiraProjects({ cloudId }),
						fetchJiraProjects({ cloudId, query }),
					]);
					const projectData = handleProjectData(projectResult);
					await handleRecentProjectData(recentProjectResult, projectData);
				}
			} finally {
				setLoading(false);
			}
		};

		const handleProjectData = (projectResult: PromiseSettledResult<JiraProjects | undefined>) => {
			if (projectResult.status === 'fulfilled') {
				setProjects(projectResult.value);
				return projectResult.value;
			} else {
				setError(`Failed to fetch projects: ${projectResult.reason}`);
			}
		};

		const handleRecentProjectData = async (
			recentProjectResult: PromiseSettledResult<JiraProjects | undefined>,
			projectData: JiraProjects | undefined,
		) => {
			if (recentProjectResult.status === 'fulfilled') {
				const recentProjectData = recentProjectResult.value;
				const recentProjectIds = recentProjectData?.map((project) => project.id);
				if (recentProjectIds && recentProjectIds.length > 0) {
					await fetchAndSetRecentProjects(recentProjectIds, projectData);
				} else {
					setRecentProjects([]);
					onComplete?.([], projectData);
				}
			} else {
				setError(`Failed to fetch recent projects: ${recentProjectResult.reason}`);
				setRecentProjects([]);
				onComplete?.([], projectData);
			}
		};

		const fetchAndSetRecentProjects = async (
			recentProjectIds: string[],
			projectData: JiraProjects | undefined,
		) => {
			try {
				const recentProjectsWithIssueCreatePermissionData = await fetchJiraProjects({
					cloudId,
					query,
					projectIds: recentProjectIds,
				});
				setRecentProjects(recentProjectsWithIssueCreatePermissionData);
				onComplete?.(recentProjectsWithIssueCreatePermissionData, projectData);
			} catch (e) {
				setError(
					`Failed to fetch recent projects with issue create permission: ${(e as Error).message}`,
				);
				onComplete?.([], projectData);
			}
		};
		void fetchProjects();
	}, [cloudId, query, onComplete, setRecentProjects, setProjects]);
	return { recentProjects, projects, loading, error };
};
