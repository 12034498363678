import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Flex, Box, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { IconButton } from '@atlaskit/button/new';
import { JiraIcon } from '@atlaskit/logo';

import { messages } from './messages';

const headerStyles = xcss({
	flexShrink: 0,
});

const headerMessageStyle = xcss({
	paddingInline: 'space.100',
	font: 'font.heading.small',
	fontWeight: 'font.weight.medium',
	fontFamily: 'font.family.body',
	paddingBottom: 'space.025',
});

export const BulkCreateHeader = ({ onClose }: { onClose: () => void }) => {
	return (
		<Box
			padding="space.200"
			paddingBlockEnd="space.100"
			paddingInlineStart="space.300"
			xcss={headerStyles}
		>
			<Flex direction="row" justifyContent="space-between" alignItems="center">
				<Heading size="medium" as="h2">
					<Flex direction="row" justifyContent="space-between" alignItems="center">
						<Box>
							<JiraIcon size="small" appearance="brand" />
						</Box>
						<Box xcss={headerMessageStyle}>
							<FormattedMessage {...messages.bulkCreateHeaderText} />
						</Box>
					</Flex>
				</Heading>
				<IconButton
					icon={() => <CrossIcon label="close-icon" size="small" />}
					appearance="subtle"
					label="close"
					onClick={onClose}
					testId="close-issue-create-panel-button"
				/>
			</Flex>
		</Box>
	);
};
