import type { FC } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import ModalDialog, {
	ModalFooter,
	ModalBody,
	ModalTitle,
	ModalHeader,
} from '@atlaskit/modal-dialog';
import { Inline, xcss } from '@atlaskit/primitives';

import { messages } from './messages';

type IssueCreateWarningDialogProps = {
	onConfirm: () => void;
	onCancel?: () => void;
};

const footerStyles = xcss({
	display: 'flex',
	justifyContent: 'flex-end',
	flex: 1,
	gap: 'space.050',
});

export const IssueCreateWarningDialog: FC<IssueCreateWarningDialogProps> = ({
	onConfirm,
	onCancel,
}) => {
	const handleConfirm = () => {
		onConfirm();
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const Footer = () => {
		return (
			<ModalFooter>
				<Inline xcss={footerStyles}>
					<Button testId="issue-create-warning-cancel-button" onClick={handleCancel}>
						<FormattedMessage {...messages.unsavedActionCancel} />
					</Button>
					<Button
						testId="issue-create-warning-ok-button"
						appearance="warning"
						onClick={handleConfirm}
					>
						<FormattedMessage {...messages.unsavedActionDiscard} />
					</Button>
				</Inline>
			</ModalFooter>
		);
	};

	return (
		<FormattedMessage {...messages.unsavedHeading}>
			{(text) => (
				<ModalDialog>
					<ModalHeader>
						<ModalTitle appearance="warning">{text}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<FormattedMessage {...messages.unsavedContent} />
					</ModalBody>
					<Footer />
				</ModalDialog>
			)}
		</FormattedMessage>
	);
};
