import { useEffect, useState } from 'react';

import type { FetchIssueTypeDataProps, JiraIssueTypes } from '../__types__/apiUtils';

import { fetchIssueTypes } from './apiUtils';

export const useGetIssueTypes = ({ cloudId, projectId, onComplete }: FetchIssueTypeDataProps) => {
	const [issueTypes, setIssueTypes] = useState<JiraIssueTypes | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);
	useEffect(() => {
		if (!cloudId || !projectId) {
			setIssueTypes(undefined);
			return;
		}
		const fetchIssues = async () => {
			setLoading(true);
			try {
				const data = await fetchIssueTypes({ cloudId, projectId });
				setIssueTypes(data);
				onComplete?.(data);
			} catch (e: Error | unknown) {
				setError((e as Error).message);
			} finally {
				setLoading(false);
			}
		};
		void fetchIssues();
	}, [cloudId, projectId, onComplete, setIssueTypes]);
	return { issueTypes, loading, error };
};
