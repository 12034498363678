import React, { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { Field } from '@atlaskit/form';
import Select, { ValueType } from '@atlaskit/select';

import {
	Option,
	ProjectOrIssueTypeOption,
	ProjectOrIssueTypeSingleValue,
} from './__types__/BulkConfigureTypes';
import { messages } from './messages';
import { mapProjectToOption } from './utils/configureUtils';
import { ProjectSelectorProps } from './__types__/ProjectSelectorProps';

export const ProjectSelector = ({
	value,
	projects,
	recentProjects,
	isLoading,
	onChange,
	onInputChange,
}: ProjectSelectorProps) => {
	const { formatMessage } = useIntl();
	const options = useMemo(() => {
		if (recentProjects && recentProjects.length > 0) {
			return [
				{
					label: formatMessage(messages.bulkConfigureFormProjectSelectorRecentProjects),
					options: recentProjects.map(mapProjectToOption),
				},
				{
					label: formatMessage(messages.bulkConfigureFormProjectSelectorProjects),
					options: projects?.map(mapProjectToOption) || [],
				},
			];
		}
		return [{ options: projects?.map(mapProjectToOption) || [] }];
	}, [projects, recentProjects, formatMessage]);

	return (
		<Field<ValueType<Option>>
			name="projects"
			label={formatMessage(messages.bulkConfigureFormProject)}
			testId="bulk-create-project-field"
		>
			{({ fieldProps: { id, ...rest } }) => (
				<Fragment>
					<Select<Option>
						inputId={id}
						{...rest}
						isLoading={isLoading}
						onInputChange={onInputChange}
						defaultValue={value ? mapProjectToOption(value) : undefined}
						value={value ? mapProjectToOption(value) : undefined}
						options={options}
						onChange={(selectedOption) => onChange(selectedOption)}
						components={{
							Option: ProjectOrIssueTypeOption,
							SingleValue: ProjectOrIssueTypeSingleValue,
						}}
					/>
				</Fragment>
			)}
		</Field>
	);
};
