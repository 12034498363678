import { useCallback } from 'react';

import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';
import { useCreateBulkIssues } from './useBulkCreateIssues';
import { CreateIssueResponse } from '../__types__/apiUtils';

export const useCreateIssue = () => {
	const [{ aiBulkIssuesList, currentJiraProject }] = useBulkCreateContext();
	const { createBulkIssuesHandler, loading, error, response } = useCreateBulkIssues();

	const createIssueHandler = useCallback(
		async ({
			issueId,
			onComplete,
			onFailure,
		}: {
			issueId: string;
			onComplete: (response: CreateIssueResponse) => void;
			onFailure: (error: Error | unknown) => void;
		}) => {
			const issue = aiBulkIssuesList.find((item) => item.id === issueId);
			if (!issue || !currentJiraProject) {
				return;
			}

			void createBulkIssuesHandler({
				issueBody: {
					issueUpdates: [
						{
							fields: {
								description: issue.description,
								issuetype: { id: issue.issueType.id },
								project: { id: currentJiraProject.id },
								summary: issue.summary,
							},
						},
					],
				},
				onComplete: (bulkCreateResponse) => {
					const result = bulkCreateResponse.issues?.[0];
					if (result) {
						onComplete({ ...result, summary: issue.summary });
					}
				},
				onFailure,
			});
		},
		[aiBulkIssuesList, currentJiraProject, createBulkIssuesHandler],
	);

	return { createIssueHandler, loading, error, response };
};
