import React from 'react';

import { Box, Inline, xcss } from '@atlaskit/primitives';

import { AiSubFooter } from './AiSubFooter';
import { BulkCreateButtonGroup } from './BulkCreateButtonGroup';
import { IssueCreateAiFeedback } from './IssueCreateAiFeedback';

const footerBaseStyles = xcss({
	backgroundColor: 'elevation.surface',
	borderTopColor: 'color.border',
	borderTopStyle: 'solid',
	borderTopWidth: 'border.width',
	boxShadow: 'elevation.shadow.overflow',
	flexShrink: 0,
});

// Need to figure out which contentId we want to use here - either same one for single create feedback or different one for bulk
const contentId = 'contentId';

export const BulkCreateFooter = ({ onClose }: { onClose: () => void }) => {
	return (
		<Box paddingBlock="space.200" paddingInline="space.300" xcss={footerBaseStyles}>
			<Inline>
				<IssueCreateAiFeedback contentId={contentId} />
				<BulkCreateButtonGroup onClose={onClose} />
			</Inline>
			<AiSubFooter />
		</Box>
	);
};
