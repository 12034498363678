import { AvailableSite } from '@atlassian/gic-anywhere';
import { JiraProject } from '../__types__/apiUtils';
import { Option } from '../__types__/BulkConfigureTypes';
import { IssueType } from '../providers/BulkCreateContextProvider';

// Projects and Issue Types aratar URLs are returned wrongly for Stargate requests.
// This function corrects the URLs.
// This can be removed once the Stargate issue is fixed. Ticket: https://donut-world-dogfood.atlassian.net/browse/MCRTB-1099
export const transformStargateUrl = (url: string): string => {
	try {
		if (url && url.includes('ex/jira') && !url.includes('gateway/api')) {
			const urlObject = new URL(url);
			let urlPathArray = urlObject.pathname.split('/');
			urlPathArray = ['gateway', 'api', ...urlPathArray.slice(1)];
			return `${urlObject.origin}/${urlPathArray.join('/')}${urlObject.search}`;
		}
	} catch (e) {
		return url;
	}
	return url;
};

export const mapSiteToOption = (site: AvailableSite): Option => {
	return {
		label: site.displayName,
		value: site,
	};
};

export const mapProjectToOption = (project: JiraProject): Option => {
	return {
		label: project.name,
		value: project,
		avatarUrl: transformStargateUrl(project.avatarUrls['48x48']),
	};
};

export const mapIssueTypeToOption = (issueType: IssueType): Option => {
	return {
		label: issueType.name,
		value: issueType,
		avatarUrl: transformStargateUrl(issueType.iconUrl),
	};
};
