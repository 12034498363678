import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Box, Inline, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';

import { messages } from './messages';
import { GreyAtlassianIntelligenceIcon } from './assets/GreyAtlassianIntelligenceIcon';

const aiSubFooterWrapperStyle = xcss({
	borderTopColor: 'color.border',
	borderTopStyle: 'solid',
	borderTopWidth: 'border.width',
	paddingBlock: 'space.100',
	paddingInline: 'space.100',
});

const boxStyle = xcss({
	font: 'font.body.small',
	display: 'flex',
	alignItems: 'center',
});

const infoIconStyle = xcss({
	paddingRight: 'space.050',
	lineHeight: '0px',
	color: 'color.text.subtlest',
});

const aiIconStyle = xcss({
	paddingLeft: 'space.050',
	lineHeight: '6px',
});

const textColorStyle = xcss({
	color: 'color.text.subtlest',
	font: 'font.body.small',
});

export const AiSubFooter = () => (
	<Box xcss={aiSubFooterWrapperStyle}>
		<Inline spread="space-between" alignBlock="center">
			<Inline alignBlock="center">
				<Box xcss={infoIconStyle}>
					<EditorPanelIcon label="information" size="medium" />
				</Box>
				<Button
					appearance="subtle-link"
					spacing="none"
					href="https://www.atlassian.com/trust/atlassian-intelligence"
					rel="noopener"
					target="_blank"
					testId="issue-create-side-panel.ai-footer.disclaimer-text"
				>
					<Box xcss={textColorStyle}>
						<FormattedMessage {...messages.sidePanelFooterDisclaimerText} />
					</Box>
				</Button>
			</Inline>
			<Box xcss={boxStyle}>
				<Box xcss={textColorStyle}>
					<Button
						appearance="subtle-link"
						spacing="none"
						href="https://support.atlassian.com/organization-administration/docs/understand-atlassian-intelligence-features-in-products/"
						rel="noopener"
						target="_blank"
						testId="issue-create-side-panel.ai-footer.powered-by-ai-text"
					>
						<Box xcss={textColorStyle}>
							<FormattedMessage {...messages.sidePanelFooterAIText} />
						</Box>
					</Button>
				</Box>
				<Box xcss={aiIconStyle}>
					<GreyAtlassianIntelligenceIcon />
				</Box>
			</Box>
		</Inline>
	</Box>
);
