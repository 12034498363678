// Error messages
export const ERROR_IFRAME_SOURCE_CHAR_LIMIT_EXCEEDED =
	'Jira GIC Anywhere - Iframe Source character limit exceeded';

// Events from Jira Embed Issue Create
export const JIRA_EMBED_ISSUE_CREATE_INIT = 'jiraEmbedIssueCreateInit';
export const JIRA_EMBED_ISSUE_CREATE_READY = 'jiraEmbedIssueCreateReady';
export const JIRA_EMBED_ISSUE_CREATE_SUCCESS = 'jiraEmbedIssueCreateSuccess';
export const JIRA_EMBED_ISSUE_CREATE_FAILURE = 'jiraEmbedIssueCreateAppFailed';
export const JIRA_EMBED_ISSUE_CREATE_CLOSE = 'jiraEmbedIssueCreateClose';
