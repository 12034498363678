import { cfetch } from '@confluence/network';

import type {
	CreateBulkIssuesProps,
	CreateBulkIssuesResponse,
	CreateIssueProps,
	CreateIssueResponse,
	FetchIssueTypeDataProps,
	FetchProjectDataProps,
	JiraIssueMetadataResponse,
	JiraIssueTypes,
	JiraProjects,
} from '../__types__/apiUtils';

const getJiraProjectsEndpoint = ({ cloudId, query, projectIds }: FetchProjectDataProps) => {
	let endpoint = `/gateway/api/ex/jira/${cloudId}/rest/api/3/project/search?action=create`;
	if (projectIds && projectIds.length > 0) {
		endpoint += `&id=${projectIds.join('&id=')}`;
	}
	if (query && query.length > 0) {
		endpoint += `&query=${query}`;
	}
	return endpoint;
};

export const fetchJiraProjects = async ({
	cloudId,
	query,
	projectIds,
}: FetchProjectDataProps): Promise<JiraProjects | undefined> => {
	try {
		const response = await cfetch(getJiraProjectsEndpoint({ cloudId, query, projectIds }), {
			method: 'GET',
			headers: {
				Accept: 'application/json',
			},
			credentials: 'include',
		});

		const data = await response.json();

		const projects = data?.values;

		if (!projects) {
			throw new Error('Parsing response from fetchJiraProjects failed');
		}

		return projects;
	} catch (_error: Error | unknown) {
		throw new Error('Request to fetchJiraProjects agent failed');
	}
};

const getRecentJiraProjectsEndpoint = (cloudId: string) =>
	`/gateway/api/ex/jira/${cloudId}/rest/api/3/project/recent`;

export const fetchRecentJiraProjects = async ({
	cloudId,
}: FetchProjectDataProps): Promise<JiraProjects | undefined> => {
	try {
		if (!cloudId) {
			throw new Error('cloudId is required');
		}
		const response = await cfetch(getRecentJiraProjectsEndpoint(cloudId), {
			method: 'GET',
			headers: {
				Accept: 'application/json',
			},
			credentials: 'include',
		});

		const recentProjects = await response.json();

		if (!recentProjects) {
			throw new Error('Parsing response from getRecentJiraProjects failed');
		}

		return recentProjects;
	} catch (_error: Error | unknown) {
		throw new Error('Request to fetchRecentJiraProjects agent failed');
	}
};

export const getIssueTypesEndpoint = ({ cloudId, projectId }: FetchIssueTypeDataProps) =>
	`/gateway/api/ex/jira/${cloudId}/rest/api/3/issue/createmeta/${projectId}/issuetypes`;

export const fetchIssueTypes = async ({
	cloudId,
	projectId,
}: FetchIssueTypeDataProps): Promise<JiraIssueTypes | undefined> => {
	try {
		const response = await cfetch(getIssueTypesEndpoint({ cloudId, projectId }), {
			method: 'GET',
			headers: {
				Accept: 'application/json',
			},
			credentials: 'include',
		});

		const data = await response.json();

		const issues = data?.issueTypes;

		if (!issues) {
			throw new Error('Parsing response from fetchIssueTypes failed');
		}

		return issues;
	} catch (_error: Error | unknown) {
		throw new Error('Request to fetchIssueTypes agent failed');
	}
};

export const getIssueCreateEndpoint = (cloudId: string) =>
	`/gateway/api/ex/jira/${cloudId}/rest/api/3/issue`;

export const issueCreatePostRequest = ({ cloudId, issueBody }: CreateIssueProps) => {
	return cfetch(getIssueCreateEndpoint(cloudId), {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		credentials: 'include',
		body: JSON.stringify(issueBody),
	});
};

export const createIssue = async ({
	cloudId,
	issueBody,
}: CreateIssueProps): Promise<CreateIssueResponse | undefined> => {
	try {
		const response = await issueCreatePostRequest({ cloudId, issueBody });

		const data = await response.json();

		if (!data) {
			throw new Error('Parsing response from createIssue failed');
		}

		return data;
	} catch (_error: Error | unknown) {
		throw new Error('Request to createIssue agent failed');
	}
};

export const getBulkIssueCreateEndpoint = (cloudId: string) =>
	`/gateway/api/ex/jira/${cloudId}/rest/api/3/issue/bulk`;

export const createBulkIssues = async ({
	cloudId,
	issueBody,
}: CreateBulkIssuesProps): Promise<CreateBulkIssuesResponse | undefined> => {
	try {
		const response = await cfetch(getBulkIssueCreateEndpoint(cloudId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify(issueBody),
		});

		const data = await response.json();

		if (!data) {
			throw new Error('Parsing response from createBulkIssues failed');
		}

		return data;
	} catch (_error: Error | unknown) {
		throw new Error('Request to createBulkIssues agent failed');
	}
};

export const promiseAllSettled = <T>(tasks: Array<() => Promise<T>>, limit: number) => {
	return new Promise<Array<PromiseSettledResult<T>>>((resolve) => {
		const results: Array<PromiseSettledResult<T>> = [];
		if (tasks.length <= 0) return resolve(results);
		let currentIndex = 0;
		let activePromises = 0;
		const processNext = () => {
			while (activePromises < limit && currentIndex < tasks.length) {
				const index = currentIndex++;
				activePromises++;
				tasks[index]().then(
					(value) => handleSettlement(index, { status: 'fulfilled', value }),
					(reason) => handleSettlement(index, { status: 'rejected', reason }),
				);
			}
		};
		const handleSettlement = (index: number, result: PromiseSettledResult<T>) => {
			results[index] = result;
			activePromises--;
			if (currentIndex >= tasks.length && activePromises === 0) {
				resolve(results);
			} else {
				processNext();
			}
		};
		processNext();
	});
};

const getIssuesMetadataEndpoint = ({
	cloudId,
	projectId,
	issueTypeId,
}: {
	cloudId: string;
	projectId: string;
	issueTypeId: string;
}) => {
	return `/gateway/api/ex/jira/${cloudId}/rest/api/3/issue/createmeta/${projectId}/issuetypes/${issueTypeId}`;
};

export const fetchIssuesMetadata = async ({
	cloudId,
	projectId,
	issueTypeId,
}: {
	cloudId: string;
	projectId: string;
	issueTypeId: string;
}): Promise<JiraIssueMetadataResponse | undefined> => {
	try {
		const response = await cfetch(getIssuesMetadataEndpoint({ cloudId, projectId, issueTypeId }), {
			method: 'GET',
			headers: {
				Accept: 'application/json',
			},
			credentials: 'include',
		});

		const data = await response.json();
		if (!data) {
			throw new Error('Parsing response from issues metadata failed');
		}

		return data;
	} catch (_error: Error | unknown) {
		throw new Error('Error retrieving issue metadata');
	}
};
