import { fg } from '@confluence/feature-gating';
import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
	keys as localStorageKeys,
} from '@confluence/storage-manager';

export enum ISSUE_CREATE_WITH_AI {
	enabled = 'enabled',
	disabled = 'disabled',
}

export const saveCloudIdToLocalStorage = (payload: { selectedCloudId: string }) => {
	if (payload?.selectedCloudId && fg('jira_ai_issue_create_site_user_preference')) {
		try {
			localStorage.setItem(
				PERSISTED_KEYS_ON_SERVER.PERSISTED_JIRA_CLOUD_ID_PREFERENCE,
				payload.selectedCloudId,
			);
		} catch (error: unknown) {
			throw new Error('Unable to fetch cloudId');
		}
	}
};

export const getPersistedOverrideCloudId = () =>
	fg('jira_ai_issue_create_site_user_preference')
		? localStorage?.getItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_JIRA_CLOUD_ID_PREFERENCE)
		: undefined;

export const getIsCreateIssueWithAiEnabledByUser = () => {
	const isCreateIssueAiEnabled = localStorage?.getItem(localStorageKeys.ISSUE_CREATE_WITH_AI);
	// If the value is not set true should be returned by default
	return isCreateIssueAiEnabled !== ISSUE_CREATE_WITH_AI.disabled;
};

export const setCreateIssueWithAiEnabledByUser = (enabled: boolean) => {
	localStorage?.setItem(
		localStorageKeys.ISSUE_CREATE_WITH_AI,
		enabled ? ISSUE_CREATE_WITH_AI.enabled : ISSUE_CREATE_WITH_AI.disabled,
	);
};
