import { useEffect } from 'react';

import { doc, p } from '@atlaskit/adf-utils/builders';

import type { AiBulkIssuesListType } from '../providers/BulkCreateContextProvider';
import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';
export const mockData: AiBulkIssuesListType = [
	{
		id: '1',
		summary: 'Interactive Treat Toys and Crazy Scratchers',
		description: doc(p('')),
		issueType: {
			iconUrl:
				'https://product-fabric.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10315',
			name: 'Story',
			id: '39145',
		},
	},
	{
		id: '2',
		summary: 'Flavorful Biscuit Bites and Itty Bitty Kibbles',
		description: doc(p('')),
		issueType: {
			iconUrl:
				'https://product-fabric.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10315',
			name: 'Story',
			id: '39146',
		},
	},
	{
		id: '3',
		summary: 'Choose packaging providers',
		description: doc(p('')),
		issueType: {
			iconUrl:
				'https://product-fabric.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318',
			name: 'Task',
			id: '39147',
		},
	},
	{
		id: '4',
		summary:
			'Flavorful Biscuit Bites and Itty Bitty Kibbles. This is a really long issue summary to test if the ellipsis work as expected',
		description: doc(p('')),
		issueType: {
			iconUrl:
				'https://product-fabric.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10315',
			name: 'Story',
			id: '39148',
		},
	},
];

export const useAiBulkFetchIssuesList = () => {
	const [, { setAiBulkIssuesList }] = useBulkCreateContext();

	useEffect(() => void setAiBulkIssuesList(mockData), [setAiBulkIssuesList]);
};
