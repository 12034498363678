import React from 'react';
import { SingleValueProps, OptionProps, components } from '@atlaskit/select';
import Avatar from '@atlaskit/avatar';
import { Inline, Text } from '@atlaskit/primitives';
import { AvailableSite } from '@atlassian/gic-anywhere';
import { JiraIssue, JiraProject } from './apiUtils';
import { IssueType } from '../providers/BulkCreateContextProvider';

export interface Option {
	label: string;
	value: AvailableSite | JiraProject | JiraIssue | IssueType;
	avatarUrl?: string;
}

export const ProjectOrIssueTypeOption = (props: OptionProps<Option>) => (
	<components.Option {...props}>
		<Inline space="space.100" alignBlock="center">
			<Avatar appearance="square" size="xsmall" src={props.data.avatarUrl} />
			<Text size="medium">{props.label}</Text>
		</Inline>
	</components.Option>
);

export const ProjectOrIssueTypeSingleValue = (props: SingleValueProps<Option>) => (
	<components.SingleValue {...props}>
		<Inline space="space.100" alignBlock="center">
			<Avatar appearance="square" size="xsmall" src={props.data.avatarUrl} />
			<Text size="medium">{props.data.label}</Text>
		</Inline>
	</components.SingleValue>
);
