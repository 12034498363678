import React, { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { DefaultIssueTypeSelectorProps } from './__types__/DefaultIssueTypeSelectorProps';
import {
	Option,
	ProjectOrIssueTypeOption,
	ProjectOrIssueTypeSingleValue,
} from './__types__/BulkConfigureTypes';
import { Field } from '@atlaskit/form';
import Select, { ValueType } from '@atlaskit/select';

import { messages } from './messages';
import { mapIssueTypeToOption } from './utils/configureUtils';

export const DefaultIssueTypeSelector = ({
	value,
	values,
	isLoading,
	isDisabled,
	onChange,
}: DefaultIssueTypeSelectorProps) => {
	const { formatMessage } = useIntl();
	const options = useMemo(() => {
		return values?.map(mapIssueTypeToOption);
	}, [values]);

	return (
		<Field<ValueType<Option>>
			name="defaultIssueTypes"
			label={formatMessage(messages.bulkConfigureFormDefaultIssueType)}
			testId="bulk-create-default-issue-type-field"
		>
			{({ fieldProps: { id, ...rest } }) => (
				<Fragment>
					<Select<Option>
						inputId={id}
						{...rest}
						options={options}
						isLoading={isLoading}
						defaultValue={value ? mapIssueTypeToOption(value) : undefined}
						value={value ? mapIssueTypeToOption(value) : undefined}
						onChange={(selectedOption) => onChange(selectedOption)}
						components={{
							Option: ProjectOrIssueTypeOption,
							SingleValue: ProjectOrIssueTypeSingleValue,
						}}
						isDisabled={isDisabled}
					/>
				</Fragment>
			)}
		</Field>
	);
};
