import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	sidePanelHeader: {
		id: 'issue-create-side-panel.header',
		defaultMessage: 'Create Jira issue',
		description: 'Title of the side panel that allows users to create a Jira issue',
	},
	sidePanelFooterSubmit: {
		id: 'issue-create-side-panel.footer.submit',
		defaultMessage: 'Submit',
		description: 'Submit button text for the side panel that allows users to create a Jira issue',
	},
	sidePanelFooterCancel: {
		id: 'issue-create-side-panel.footer.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for the side panel that allows users to close side panel',
	},
	sidePanelIssueCreateInsertionFailed: {
		id: 'issue-create-side-panel.insertion-failed',
		defaultMessage:
			'We were unable to insert a link into the page for <link>{issueKey} {issueSummary}</link>',
		description: 'Error message for when issue insertion to the page fails',
	},
	sidePanelFooterDisclaimerText: {
		id: 'issue-create-side-panel.footer.disclaimer-text',
		defaultMessage: 'Content quality may vary',
		description: 'Info Text in the footer.',
	},
	sidePanelIssueCreateAiToggleLabel: {
		id: 'issue-create-side-panel.ai-toggle-label',
		defaultMessage: 'Use AI to generate summary and description',
		description: 'Label for the AI toggle switch',
	},
	sidePanelFooterAIText: {
		id: 'issue-create-side-panel.footer.ai-text',
		defaultMessage: 'Powered by AI',
		description: 'Text that describes that this feature is powered by Atlassian Intelligence',
	},
	sidePanelAiLoading: {
		id: 'issue-create-side-panel.body.ai-loading',
		defaultMessage: 'Generating issue',
		description: 'Text that shows the AI issue create component is loading',
	},
	jiraSiteHeading: {
		id: 'issue-create-side-panel.body.site.heading',
		defaultMessage: 'Site:',
		description: 'The heading text for displaying the selected Jira Site in the bulk create panel',
	},
	jiraProjectHeading: {
		id: 'issue-create-side-panel.body.project.heading',
		defaultMessage: 'Project:',
		description:
			'The heading text for displaying the selected Jira Project in the bulk create panel',
	},
	singleCreateAiErrorDescription: {
		id: 'issue-create-side-panel.single-create-ai-error-description',
		defaultMessage:
			'Atlassian Intelligence can‘t provide a response right now. Try again or create your issue manually.',
		description:
			'Error message for when Atlassian Intelligence results fail to be fetched for single issue create',
	},
	bulkCreateAiErrorDescription: {
		id: 'issue-create-side-panel.bulk-create-ai-error-description',
		defaultMessage:
			'Atlassian Intelligence can’t provide a response right now. Add more content to your page and try again. Or highlight specific text on the page to create manually.',
		description:
			'Error message for when Atlassian Intelligence results fail to be fetched for bulk issue creation',
	},
	aiErrorTryAgainButton: {
		id: 'issue-create-side-panel.ai-error.button.try-again',
		defaultMessage: 'Try again',
		description: 'Text displayed on try again button',
	},
	aiErrorCreateManuallyButton: {
		id: 'issue-create-side-panel.ai-error.button.create-manually',
		defaultMessage: 'Create Manually',
		description: 'Text displayed on create manually button',
	},
	sidePanelSingleIssueCreateSuccessFlagTitle: {
		id: 'issue-create-side-panel.sucess-flag-title',
		defaultMessage: 'You’ve created <link>{issueKey} {issueSummary}</link> issue',
		description: 'Title of the success flag that is shown when an issue is created successfully',
	},
	sidePanelMultipleIssueCreateSuccessFlagTitle: {
		id: 'issue-create-side-panel.multiple.sucess-flag-title',
		defaultMessage: 'You’ve created {issueCount} {issueCount, plural, one {issue} other {issues}}',
		description:
			'Title of the success flag that is shown when multiple issues are created successfully',
	},
	sidePanelMultipleIssueCreateSuccessFlagViewIssue: {
		id: 'issue-create-side-panel.multiple.sucess-flag-view-issue',
		defaultMessage: 'View {issueCount, plural, one {issue} other {issues}}',
		description:
			'Label of the view isues link in the success flag that is shown when multiple issues are created successfully',
	},
	sidePanelMultipleIssueCreateSuccessFlagCopyLink: {
		id: 'issue-create-side-panel.multiple.sucess-flag-copy-link',
		defaultMessage: 'Copy link',
		description:
			'Label of the copy link action in the success flag that is shown when multiple issues are created successfully',
	},
	sidePanelIssueCreateErrorMessage: {
		id: 'issue-create-side-panel.bulk-create.error.message',
		defaultMessage:
			'We weren’t able to save your {issuesCount, plural, one {issue} other {issues}}. Please try again.',
		description: 'Section error message displayed when issue creation fails',
	},
	sidePanelIssueCreatePartialErrorMessage: {
		id: 'issue-create-side-panel.bulk-create.partial.error.message',
		defaultMessage:
			'We couldn’t create some of your issues. Select the checkmark on the remaining issues to create them one at a time.',
		description:
			'Section error message displayed when some issues creation fails asking user to manually create the failed ones',
	},
	sidePanelBetaText: {
		id: 'issue-create-side-panel.beta-text',
		defaultMessage: 'Beta',
		description: 'Beta lozenge displayed in the header of the side panel',
	},
	unsavedHeading: {
		id: 'issue-create-side-panel.unsaved-issue.warning.dialog.heading',
		defaultMessage: 'Discard issue?',
		description:
			'The heading text for the warning modal confirming the user wants to continue and discard their Jira issue',
	},
	unsavedContent: {
		id: 'issue-create-side-panel.unsaved-issue.warning.dialog.content',
		defaultMessage: 'Your issue will be lost.',
		description:
			'The body text for the warning modal explaining the unsaved issue content will be lost',
	},
	unsavedActionDiscard: {
		id: 'issue-create-side-panel.unsaved-issue.warning.dialog.action.discard',
		defaultMessage: 'Discard',
		description: 'The action text for the warning modal to discard the unsaved issue',
	},
	unsavedActionCancel: {
		id: 'issue-create-side-panel.unsaved-issue.warning.dialog.action.cancel',
		defaultMessage: 'Cancel',
		description: 'The action text for the warning modal to cancel the warning dialog',
	},
	bulkCreateHeaderText: {
		id: 'issue-create-side-panel.bulk-create.header',
		defaultMessage: 'Create Jira issues',
		description: 'Header text for the bulk create view',
	},
	bulkCreateCreateAllFooterButton: {
		id: 'issue-create-side-panel.bulk-create.footer-button.create-all',
		defaultMessage: 'Create all',
		description: "Bulk Create view's footer button text for the create all button",
	},
	bulkCreateCancelFooterButton: {
		id: 'issue-create-side-panel.bulk-create.footer-button.cancel',
		defaultMessage: 'Cancel',
		description: "Bulk Create view's footer button text for the cancel button",
	},
	bulkCreateEditHeaderText: {
		id: 'issue-create-side-panel.bulk-create-edit.header',
		defaultMessage: 'Edit issue',
		description: "Header text for the bulk create's edit issue view",
	},
	bulkCreateConfigureBodyText: {
		id: 'issue-create-side-panel.bulk-create.configure.body',
		defaultMessage: 'Changing these settings might re-generate the issues list.',
		description: 'Body text for the bulk create configure edit view',
	},
	bulkConfigureHeaderText: {
		id: 'issue-create-side-panel.bulk-configure.header',
		defaultMessage: 'Configure',
		description: 'Header text for the bulk configure view',
	},
	bulkConfigureFormSite: {
		id: 'issue-create-side-panel.bulk-configure.form.site',
		defaultMessage: 'Site',
		description: 'Site field label for the bulk configure view',
	},
	bulkConfigureFormProject: {
		id: 'issue-create-side-panel.bulk-configure.form.project',
		defaultMessage: 'Project',
		description: 'Project field label for the bulk configure view',
	},
	bulkConfigureFormDefaultIssueType: {
		id: 'issue-create-side-panel.bulk-configure.form.default.issue.type',
		defaultMessage: 'Default Issue type',
		description: 'Default Issue type field label for the bulk configure view',
	},
	bulkConfigureFormProjectSelectorProjects: {
		id: 'issue-create-side-panel.bulk-configure.form.project-selector.projects',
		defaultMessage: 'Projects',
		description: 'Label for the projects section in the project selector',
	},
	bulkConfigureFormProjectSelectorRecentProjects: {
		id: 'issue-create-side-panel.bulk-configure.form.project-selector.recent-projects',
		defaultMessage: 'Recent Projects',
		description: 'Label for the recent projects section in the project selector',
	},
	bulkConfigureFooterCancel: {
		id: 'issue-create-side-panel.bulk-configure.footer.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for the bulk configure view',
	},
	bulkConfigureFooterSave: {
		id: 'issue-create-side-panel.bulk-configure.footer.save',
		defaultMessage: 'Save',
		description: 'Save button text for the bulk configure view',
	},
	issueCardDescriptionIconLabel: {
		id: 'issue-create-side-panel.bulk-create.card.description-icon',
		defaultMessage: 'Description',
		description: 'Description icon label text for the issue card',
	},
	// TODO update objects below with correct fields
	requiredFieldsSectionMessageTitle: {
		id: 'issue-create-side-panel.bulk-create.body.required-fields-message-title',
		defaultMessage: 'These suggestions have required fields',
		description:
			'Title for section message displayed when selected default issue type has required fields',
	},
	requiredFieldsSectionMessageDescription: {
		id: 'issue-create-side-panel.bulk-create.body.required-fields-message-description',
		defaultMessage: 'Edit each suggestion and complete the required fields to create the issues',
		description:
			'Description for section message displayed when selected default issue type has required fields',
	},
});
