export const SCREEN = 'Screen';
export const DRAWER = 'Drawer';
export const MODAL = 'Modal';
export const INLINE_DIALOG = 'InlineDialog';
export const DROPDOWN = 'Dropdown';

export const UI_EVENT_TYPE = 'UI';
export const TRACK_EVENT_TYPE = 'TRACK';
export const SCREEN_EVENT_TYPE = 'SCREEN';
export const OPERATIONAL_EVENT_TYPE = 'OPERATIONAL';

export const ANALYTICS_BRIDGE_CHANNEL = 'analyticsBridge';
