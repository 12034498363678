import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import SectionMessage from '@atlaskit/section-message';

import { messages } from './messages';
import { useErrorMessage } from './providers/AiBulkNavigationContextProvider';

export type BulkCreateErrorType = {
	createdCount: number;
	failedCount: number;
} | null;

export const BulkCreateErrorSectionMessage = () => {
	const errorMessage = useErrorMessage();

	if (!errorMessage) {
		return null;
	}

	const { createdCount, failedCount } = errorMessage;
	const formattedMessage =
		createdCount !== 0 ? (
			<FormattedMessage {...messages.sidePanelIssueCreatePartialErrorMessage} />
		) : (
			<FormattedMessage
				{...messages.sidePanelIssueCreateErrorMessage}
				values={{ issuesCount: failedCount }}
			/>
		);

	return (
		<SectionMessage appearance="error" testId="bulkCreateErrorSectionMessage">
			{formattedMessage}
		</SectionMessage>
	);
};
