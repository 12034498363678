import React from 'react';

export const GreyAtlassianIntelligenceIcon = ({
	size = 'small',
}: {
	size?: 'smaller' | 'small' | 'large';
}) => (
	<svg
		width={size === 'smaller' ? '16' : size === 'small' ? '18' : '24'}
		height={size === 'smaller' ? '16' : size === 'small' ? '18' : '24'}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.3086 9.99996C14.3086 9.53972 14.6817 9.16663 15.1419 9.16663H16.6669C17.1272 9.16663 17.5003 9.53972 17.5003 9.99996C17.5003 10.4602 17.1272 10.8333 16.6669 10.8333H15.1419C14.6817 10.8333 14.3086 10.4602 14.3086 9.99996Z"
			fill="#626F86"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.0003 2.5C10.4606 2.5 10.8337 2.8731 10.8337 3.33333V4.85833C10.8337 5.31857 10.4606 5.69167 10.0003 5.69167C9.54009 5.69167 9.16699 5.31857 9.16699 4.85833V3.33333C9.16699 2.8731 9.54009 2.5 10.0003 2.5Z"
			fill="#626F86"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.5 9.99996C2.5 9.53972 2.8731 9.16663 3.33333 9.16663H4.85833C5.31857 9.16663 5.69167 9.53972 5.69167 9.99996C5.69167 10.4602 5.31857 10.8333 4.85833 10.8333H3.33333C2.8731 10.8333 2.5 10.4602 2.5 9.99996Z"
			fill="#626F86"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.0003 14.3083C10.4606 14.3083 10.8337 14.6814 10.8337 15.1417V16.6667C10.8337 17.1269 10.4606 17.5 10.0003 17.5C9.54009 17.5 9.16699 17.1269 9.16699 16.6667V15.1417C9.16699 14.6814 9.54009 14.3083 10.0003 14.3083Z"
			fill="#626F86"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.9111 11.9107C12.2365 11.5853 12.7641 11.5853 13.0896 11.9107L16.4229 15.244C16.7484 15.5695 16.7484 16.0971 16.4229 16.4225C16.0975 16.748 15.5698 16.748 15.2444 16.4225L11.9111 13.0892C11.5856 12.7638 11.5856 12.2361 11.9111 11.9107Z"
			fill="#626F86"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.4229 3.57745C16.7484 3.90289 16.7484 4.43053 16.4229 4.75596L13.0896 8.0893C12.7641 8.41473 12.2365 8.41473 11.9111 8.0893C11.5856 7.76386 11.5856 7.23622 11.9111 6.91079L15.2444 3.57745C15.5698 3.25201 16.0975 3.25201 16.4229 3.57745Z"
			fill="#626F86"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.57709 3.57745C3.90252 3.25201 4.43016 3.25201 4.7556 3.57745L8.08893 6.91079C8.41437 7.23622 8.41437 7.76386 8.08893 8.0893C7.76349 8.41473 7.23586 8.41473 6.91042 8.0893L3.57709 4.75596C3.25165 4.43053 3.25165 3.90289 3.57709 3.57745Z"
			fill="#626F86"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.08893 11.9107C8.41437 12.2361 8.41437 12.7638 8.08893 13.0892L4.7556 16.4225C4.43016 16.748 3.90252 16.748 3.57709 16.4225C3.25165 16.0971 3.25165 15.5695 3.57709 15.244L6.91042 11.9107C7.23586 11.5853 7.76349 11.5853 8.08893 11.9107Z"
			fill="#626F86"
		/>
	</svg>
);
