import React, { useCallback } from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import { BulkCreateBody } from './BulkCreateBody';
import { BulkCreateHeader } from './BulkCreateHeader';
import { BulkCreateFooter } from './BulkCreateFooter';
import { SidePanelWrapper } from './SidePanelWrapper';
import {
	BULK_ISSUE_CREATE_SOURCE,
	useBulkCreateLoggingEvents,
} from './utils/useBulkCreateLoggingEvents';
import { AiStates, useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { AiCreationError } from './AiCreationError';
import { messages } from './messages';

const contentStyles = xcss({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
});

export const AiBulkDefaultView = ({ onClose }: { onClose: () => void }) => {
	const { logCancelAiPanel } = useBulkCreateLoggingEvents();
	const onPanelClose = useCallback(() => {
		logCancelAiPanel();
		onClose();
	}, [logCancelAiPanel, onClose]);
	const [state] = useBulkCreateContext();
	const { aiState } = state;
	const isAiError = aiState === AiStates.ERROR;

	return (
		<SidePanelWrapper>
			<Stack xcss={contentStyles}>
				<BulkCreateHeader onClose={onPanelClose} />
				{isAiError ? (
					<>
						<AiCreationError
							creationSource={BULK_ISSUE_CREATE_SOURCE}
							errorMessage={messages.bulkCreateAiErrorDescription}
						/>
					</>
				) : (
					<>
						<BulkCreateBody />
						<BulkCreateFooter onClose={onPanelClose} />
					</>
				)}
			</Stack>
		</SidePanelWrapper>
	);
};
