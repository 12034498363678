import {
	createStore,
	createStateHook,
	createActionsHook,
	createContainer,
	type StoreActionApi,
} from 'react-sweet-state';

type WarningDialogState = {
	isWarningDialogOpen: boolean;
};
export const initialWarningDialogState = {
	isWarningDialogOpen: false,
};

export const actions = {
	showWarningDialog:
		() =>
		({ setState }: StoreActionApi<WarningDialogState>) => {
			setState({ isWarningDialogOpen: true });
		},
	hideWarningDialog:
		() =>
		({ setState }: StoreActionApi<WarningDialogState>) => {
			setState({ isWarningDialogOpen: false });
		},
};
type WarningDialogActionType = typeof actions;

const Store = createStore<WarningDialogState, WarningDialogActionType>({
	initialState: initialWarningDialogState,
	actions,
	name: 'WarningDialogState',
});

export const IssueCreateWarningDialogContainer = createContainer(Store);

export const useWarningDialogStateActions = createActionsHook(Store);

export const useIsWarningDialogOpen = createStateHook(Store, {
	selector: (state: WarningDialogState) => state.isWarningDialogOpen,
});
