import React from 'react';

import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';

import { BulkCreateButtonGroup } from './BulkCreateButtonGroup';
import { BulkCreateBody } from './BulkCreateBody';
import { BulkCreateHeader } from './BulkCreateHeader';

const footerContainerStyle = xcss({
	backgroundColor: 'elevation.surface.sunken',
});

// This is the initial view for the bulk create side panel
export const BulkCreateDefaultView = () => (
	<Stack>
		<BulkCreateHeader onClose={() => {}} />
		<BulkCreateBody />
		<Box xcss={footerContainerStyle}>
			<Inline>
				<BulkCreateButtonGroup onClose={() => {}} />
			</Inline>
		</Box>
	</Stack>
);
