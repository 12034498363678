import React, { Fragment, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { Field } from '@atlaskit/form';
import Select, { ValueType } from '@atlaskit/select';

import { Option } from './__types__/BulkConfigureTypes';
import { messages } from './messages';
import { mapSiteToOption } from './utils/configureUtils';
import { SiteSelectorProps } from './__types__/SiteSelectorProps';

export const SiteSelector = ({
	defaultValue,
	value,
	values,
	onChange,
	isLoading,
	isDisabled,
}: SiteSelectorProps) => {
	const { formatMessage } = useIntl();
	const options = useMemo(() => {
		return values?.map(mapSiteToOption);
	}, [values]);

	return (
		<Field<ValueType<Option>>
			name="sites"
			label={formatMessage(messages.bulkConfigureFormSite)}
			testId="bulk-create-site-field"
		>
			{({ fieldProps: { id, ...rest } }) => (
				<Fragment>
					<Select<Option>
						inputId={id}
						{...rest}
						defaultValue={defaultValue ? mapSiteToOption(defaultValue) : undefined}
						value={value ? mapSiteToOption(value) : undefined}
						options={options}
						onChange={(selectedOption) => onChange(selectedOption)}
						isLoading={isLoading}
						isDisabled={isDisabled}
					/>
				</Fragment>
			)}
		</Field>
	);
};
