import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useCallback } from 'react';

type AiResultActionType = 'createAll' | 'createSingleIssue';

export const BULK_ISSUE_CREATE_SOURCE = 'confluenceBulkIssueCreate';

const getErrorMessage = (error: Error | unknown) => {
	if (error instanceof Error) return error.message;
	return String(error);
};

export const useBulkCreateLoggingEvents = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const logMultipleIssueCreateSuccess = useCallback(
		(numIssues: number) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: BULK_ISSUE_CREATE_SOURCE,
					action: 'created',
					actionSubject: 'issue',
					attributes: {
						numIssues,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const logSingleIssueCreateSuccess = useCallback(() => {
		logMultipleIssueCreateSuccess(1);
	}, [logMultipleIssueCreateSuccess]);

	const logSingleIssueCreateFailure = useCallback(
		(error: Error | unknown) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: BULK_ISSUE_CREATE_SOURCE,
					action: 'error',
					actionSubject: 'globalIssueCreate',
					attributes: {
						error,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const logCancelAiPanel = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: BULK_ISSUE_CREATE_SOURCE,
				action: 'cancelled',
				actionSubject: 'aiPanel',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const logAiResultActioned = useCallback(
		(aiResultAction: AiResultActionType) => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: BULK_ISSUE_CREATE_SOURCE,
					action: 'actioned',
					actionSubject: 'aiResult',
					attributes: {
						aiResultAction,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const logCreateAllClick = useCallback(() => {
		logAiResultActioned('createAll');
	}, [logAiResultActioned]);

	const logCreateSingleIssueClick = useCallback(() => {
		logAiResultActioned('createSingleIssue');
	}, [logAiResultActioned]);

	const logAiResultRemoved = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: BULK_ISSUE_CREATE_SOURCE,
				action: 'removed',
				actionSubject: 'aiResult',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const logAiIssueCreationFailed = useCallback(
		(numIssues: number, error: Error | unknown = '') => {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: BULK_ISSUE_CREATE_SOURCE,
					action: 'failed',
					actionSubject: 'aiIssueCreation',
					attributes: {
						errorMessage: getErrorMessage(error),
						numIssues,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	return {
		logSingleIssueCreateSuccess,
		logMultipleIssueCreateSuccess,
		logSingleIssueCreateFailure,
		logCancelAiPanel,
		logCreateAllClick,
		logCreateSingleIssueClick,
		logAiResultRemoved,
		logAiIssueCreationFailed,
	};
};
