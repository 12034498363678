import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { LinkButton } from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Text, xcss } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import Spinner from '@atlaskit/spinner';
import { useThemeObserver } from '@atlaskit/tokens';
import {
	ContextualAnalyticsData,
	fireOperationalAnalytics,
	fireTrackAnalytics,
	fireUIAnalytics,
	MODAL,
} from '@atlassian/analytics-bridge';

import errorImage from '../assets/error.png';
import {
	JIRA_EMBED_ISSUE_CREATE_CLOSE,
	JIRA_EMBED_ISSUE_CREATE_FAILURE,
	JIRA_EMBED_ISSUE_CREATE_INIT,
	JIRA_EMBED_ISSUE_CREATE_READY,
	JIRA_EMBED_ISSUE_CREATE_SUCCESS,
} from '../common/constants';
import { AnalyticsProvider, getIframeSrc } from '../common/utils';
import { useGetFirstAvailableSiteDomain } from '../controllers/use-get-first-available-site-domain';

import { DEFAULT_TIMEOUT, MAX_ERROR_IMAGE_HEIGHT, MAX_ERROR_IMAGE_WIDTH } from './constants';
import { messages } from './messages';
import { type GicAnywhereProps, type GICFrameProps, type GICPayload } from './types';

const phases = {
	loading: 'loading',
	timeout: 'timeout',
	error: 'error',
	idle: 'idle',
} as const;

type GicAnywherePhases =
	| typeof phases.loading
	| typeof phases.timeout
	| typeof phases.error
	| typeof phases.idle;

export const GicAnywhere = (props: GicAnywhereProps) => {
	return (
		<AnalyticsProvider>
			<ContextualAnalyticsData sourceType={MODAL} sourceName="gicAnywhere">
				<GicAnywhereWithoutAnalytics {...props} />
			</ContextualAnalyticsData>
		</AnalyticsProvider>
	);
};

export const GicAnywhereWithoutAnalytics = ({
	cloudId,
	overrideCloudId,
	configuration,
	summary,
	assignee,
	descriptionAdf,
	consumer,
	timeoutMs = DEFAULT_TIMEOUT,
	onInit,
	onSuccess,
	onFailure,
	onClose,
	onTimeout,
	projectId,
	issueTypeId,
	issueTypes,
	isPayloadReady = false,
}: GicAnywhereProps) => {
	di(useGetFirstAvailableSiteDomain);

	const { domain: domainName, availableSites } = useGetFirstAvailableSiteDomain({
		preferredCloudId: cloudId,
	});
	const { domain: isOverrideSiteAvailable } = fg('jira_ai_issue_create_site_user_preference')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useGetFirstAvailableSiteDomain({ preferredCloudId: overrideCloudId })
		: { domain: undefined };

	const displaySitePicker = availableSites && availableSites.length > 1;

	const [phase, setPhase] = useState<GicAnywherePhases>(phases.loading);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	useEffect(() => {
		const timeout = (() => {
			switch (phase) {
				case phases.loading:
					return setTimeout(() => {
						fireOperationalAnalytics(createAnalyticsEvent({}), 'globalIssueCreateEmbed timedOut', {
							consumer,
							timeoutMs,
						});
						setPhase(phases.timeout);
						onTimeout?.();
					}, timeoutMs);

				case phases.timeout:
					// if timeoutMs passes again after initial timeout, assume GIC is not loading and set to error state
					return setTimeout(() => {
						fireOperationalAnalytics(createAnalyticsEvent({}), 'globalIssueCreateEmbed error', {
							consumer,
							timeoutMs,
						});
						setPhase(phases.error);
						onFailure(new Error('GIC has timed out'));
					}, timeoutMs);
			}
		})();

		// set Timeout for loading, reset for any other state.
		return () => timeout && clearTimeout(timeout);
	}, [consumer, createAnalyticsEvent, onFailure, onTimeout, phase, timeoutMs]);

	// we mimic "external callback" for our needs
	const onGICReady = useCallback((): GICPayload => {
		setPhase(phases.idle);
		return {
			summary: summary || 'No summary provided' /* this is impossible case */,
			descriptionAdf: descriptionAdf || getEmptyADF(),
			projectId,
			issueTypeId,
			issueTypes: issueTypes?.join(','),
			assignee,
		};
	}, [summary, descriptionAdf, projectId, issueTypeId, issueTypes, assignee]);

	const renderPhaseInformation = useMemo(() => {
		switch (phase) {
			case phases.timeout:
			case phases.loading:
				return (
					<Box testId="gic-anywhere-loading" xcss={[gicAnywhereStyles, loadingScreenStyles]}>
						<Spinner size="large" />
						<Text>
							{formatMessage(
								phase === phases.timeout ? messages.iFrameSlowLoading : messages.iFrameLoading,
								{
									br: <br />,
								},
							)}
						</Text>
					</Box>
				);
			case phases.error:
				return (
					<Box testId="gic-anywhere-error" xcss={gicAnywhereStyles}>
						<EmptyState
							header={formatMessage(messages.errorHeader)}
							description={formatMessage(messages.errorBody)}
							primaryAction={
								<LinkButton
									onClick={() => {
										onClose();
									}}
									href={domainName || '/'}
									target="_blank"
									appearance="primary"
								>
									{formatMessage(messages.openInJiraButton)}
								</LinkButton>
							}
							imageUrl={errorImage}
							imageWidth={MAX_ERROR_IMAGE_WIDTH}
							maxImageWidth={MAX_ERROR_IMAGE_WIDTH}
							maxImageHeight={MAX_ERROR_IMAGE_HEIGHT}
						/>
					</Box>
				);
			default:
				return undefined;
		}
	}, [phase, formatMessage, domainName, onClose]);

	return (
		<>
			{renderPhaseInformation}
			<UFOLoadHold name="gic-anywhere-iframe-loading" hold={phase === phases.loading} />
			{/**
			 * Keep iframe hidden until we are ready to display it
			 */}
			<Box xcss={phase !== phases.idle ? hiddenDisplayStyle : gicAnywhereStyles}>
				{/* we cannot render iframe until domain is determined */}
				{domainName !== undefined && (
					<GICFrame
						domainName={domainName}
						consumer={consumer}
						onGICReady={onGICReady}
						onInit={onInit}
						onSuccess={onSuccess}
						onFailure={onFailure}
						onClose={onClose}
						configuration={configuration}
						displaySitePicker={displaySitePicker}
						isPayloadReady={isPayloadReady}
						// if the override site not available do not set
						{...(fg('jira_ai_issue_create_site_user_preference') && {
							overrideCloudId: isOverrideSiteAvailable ? overrideCloudId : undefined,
						})}
					/>
				)}
			</Box>
		</>
	);
};

const GICFrame = ({
	onGICReady,
	consumer,
	domainName,
	overrideCloudId,
	onClose,
	onFailure,
	onSuccess,
	onInit,
	configuration,
	displaySitePicker = true,
	isPayloadReady = false,
}: GICFrameProps) => {
	di(IFrame);

	const iframeRef = useRef<HTMLIFrameElement>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { colorMode } = useThemeObserver();
	const [isCreateSuccess, setIsCreateSuccess] = useState(false);
	const [isGicReady, setIsGicReady] = useState<boolean>(false);

	// we will react on this event only once
	// we dont want to reattach handlers on every render
	const onGICReadyRef = useRef(onGICReady);
	onGICReadyRef.current = onGICReady;

	useEffect(() => {
		const messageHandler = async (message: MessageEvent) => {
			const iframe = iframeRef.current?.contentWindow;
			if (!iframe) {
				return;
			}

			const eventName = message?.data?.event;
			const event = createAnalyticsEvent({});

			switch (eventName) {
				case JIRA_EMBED_ISSUE_CREATE_READY:
					fireOperationalAnalytics(event, 'globalIssueCreateEmbed initialised', {
						consumer,
					});

					setIsGicReady(true);

					break;
				case JIRA_EMBED_ISSUE_CREATE_SUCCESS:
					setIsCreateSuccess(true);
					onSuccess(message.data.payload);
					fireTrackAnalytics(event, 'globalIssueCreateEmbed succeeded', {
						consumer,
					});
					break;
				case JIRA_EMBED_ISSUE_CREATE_FAILURE:
					onFailure(new Error('issueCreate failed to save'));
					fireOperationalAnalytics(event, 'globalIssueCreateEmbed failed', {
						consumer,
					});
					break;
				case JIRA_EMBED_ISSUE_CREATE_CLOSE:
					onClose(isCreateSuccess);
					// GIC sends a closeEvent even when created succesfully. Hence
					// checking if no issue was created
					if (!isCreateSuccess) {
						fireUIAnalytics(event, 'globalIssueCreateEmbed closed', {
							consumer,
						});
					}
					break;

				default:
				// We don't handle other events
			}
		};

		// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
		window.addEventListener('message', messageHandler);

		return () => {
			// eslint-disable-next-line @repo/internal/dom-events/no-unsafe-event-listeners
			window.removeEventListener('message', messageHandler);
		};
	}, [
		iframeRef,
		consumer,
		createAnalyticsEvent,
		onInit,
		onClose,
		onFailure,
		onSuccess,
		isCreateSuccess,
		setIsCreateSuccess,
		setIsGicReady,
	]);

	useEffect(() => {
		if (isPayloadReady && isGicReady) {
			// Only resolve the Promise if isPayloadReady is true, and the message has been received
			Promise.resolve(onGICReadyRef.current()).then((issuePayload) => {
				const iframe = iframeRef.current?.contentWindow;
				if (iframe) {
					// Send GIC data used for initialization
					iframe.postMessage(
						{
							event: JIRA_EMBED_ISSUE_CREATE_INIT,
							payload: { defaultValues: issuePayload },
						},
						// Broadcast this message to all listeners for now
						'*',
					);
					onInit?.();
				}
			});
		}
	}, [isPayloadReady, isGicReady, onGICReadyRef, onInit]);

	return (
		<IFrame
			name="gic-anywhere-iframe"
			sandbox={undefined}
			ref={iframeRef}
			height="100%"
			width="100%"
			frameBorder={0}
			// iframes do not fire onError due to security reasons
			// onError={}
			src={getIframeSrc({
				colorMode,
				domainName,

				displaySitePicker,
				...configuration,
				...(fg('jira_ai_issue_create_site_user_preference') && { overrideCloudId }),
			})}
			data-testid="gic-anywhere"
		/>
	);
};

/**
 * iframe element isolated for DI
 */
export const IFrame = forwardRef<HTMLIFrameElement, React.ComponentProps<'iframe'>>(
	(props, ref) => {
		return <iframe title="Jira Embed Global Issue Create" ref={ref} {...props} />;
	},
);

const hiddenDisplayStyle = xcss({
	display: 'none',
});

const gicAnywhereStyles = xcss({
	width: '100%',
	height: '100%',
});

const loadingScreenStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
});
