import React from 'react';

import { BulkCreateContainer } from './BulkCreateContextProvider';
import { AiBulkNavigationContextContainer } from './AiBulkNavigationContextProvider';

export const BulkIssueCreateProvidersWrapper: React.FC = ({ children }) => {
	return (
		<BulkCreateContainer isGlobal>
			<AiBulkNavigationContextContainer>{children}</AiBulkNavigationContextContainer>
		</BulkCreateContainer>
	);
};
