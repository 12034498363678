import { useCallback } from 'react';

import { useGetFirstAvailableSiteDomain } from '@atlassian/gic-anywhere';

import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';

import { useGetJiraProjects } from './useGetJiraProjects';
import { useGetIssueTypes } from './useGetIssueTypes';

export const useGetInitialData = () => {
	const [
		{ currentJiraSite, currentJiraProject },
		{ setCurrentJiraSite, setCurrentJiraProject, setCurrentDefaultIssueType },
	] = useBulkCreateContext();

	const { loading: loadingSites } = useGetFirstAvailableSiteDomain({
		preferredCloudId: undefined,
		onComplete: useCallback(
			({ availableSites }) => {
				if (availableSites.length > 0) {
					const firstSite = availableSites[0];
					void setCurrentJiraSite(firstSite);
				}
			},
			[setCurrentJiraSite],
		),
	});

	const { loading: loadingProjects } = useGetJiraProjects({
		cloudId: currentJiraSite?.cloudId,
		query: '',
		onComplete: useCallback(
			(recentProjectData, projectData) => {
				const firstProject = recentProjectData?.[0] || projectData?.[0];
				if (firstProject) {
					void setCurrentJiraProject(firstProject);
				}
			},
			[setCurrentJiraProject],
		),
	});

	const { loading: loadingIssueTypes } = useGetIssueTypes({
		cloudId: currentJiraSite?.cloudId,
		projectId: currentJiraProject?.id,
		onComplete: useCallback(
			(data) => {
				if (data && data.length > 0) {
					void setCurrentDefaultIssueType(data[0]);
				}
			},
			[setCurrentDefaultIssueType],
		),
	});

	return {
		loading: loadingSites || loadingProjects || loadingIssueTypes,
	};
};
