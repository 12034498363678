import {
	createStore,
	createStateHook,
	createActionsHook,
	type StoreActionApi,
} from 'react-sweet-state';

type SidePanelState = {
	isSidePanelOpen: boolean;
};
export const initialSidePanelState = {
	isSidePanelOpen: false,
};

export const actions = {
	showSidePanel:
		() =>
		({ setState }: StoreActionApi<SidePanelState>) => {
			setState({ isSidePanelOpen: true });
		},
	hideSidePanel:
		() =>
		({ setState }: StoreActionApi<SidePanelState>) => {
			setState({ isSidePanelOpen: false });
		},
};
type SidePanelActionType = typeof actions;

const Store = createStore<SidePanelState, SidePanelActionType>({
	initialState: initialSidePanelState,
	actions,
	name: 'SidePanelState',
});

export const useSidePanelStateActions = createActionsHook(Store);

export const useIsSidePanelOpen = createStateHook(Store, {
	selector: (state: SidePanelState) => state.isSidePanelOpen,
});
