import { useCallback, useState } from 'react';

import type { CreateBulkIssuesBody, CreateBulkIssuesResponse } from '../__types__/apiUtils';

import { createBulkIssues } from './apiUtils';
import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';

export const useCreateBulkIssues = () => {
	const [{ currentJiraSite }] = useBulkCreateContext();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [response, setResponse] = useState<CreateBulkIssuesResponse | null>(null);

	const createBulkIssuesHandler = useCallback(
		async ({
			issueBody,
			onComplete,
			onFailure,
		}: {
			issueBody: CreateBulkIssuesBody;
			onComplete: (response: CreateBulkIssuesResponse) => void;
			onFailure: (error: Error | unknown) => void;
		}) => {
			if (!currentJiraSite || !currentJiraSite.cloudId) {
				return;
			}
			setLoading(true);
			setError(null);
			setResponse(null);
			try {
				const result = await createBulkIssues({ cloudId: currentJiraSite.cloudId, issueBody });
				if (!result) {
					setError('Failed to create issue');
					onFailure('Empty response');
				} else {
					setResponse(result);
					onComplete(result);
				}
			} catch (err) {
				setError('Failed to create issue');
				onFailure(err);
			} finally {
				setLoading(false);
			}
		},
		[currentJiraSite],
	);

	return { createBulkIssuesHandler, loading, error, response };
};
