import { BannerStateContainer } from '@confluence/banners';
import { SidePanel } from '@confluence/side-panel';
import React from 'react';
import { Subscribe } from 'unstated';

export const SidePanelWrapper = ({
	children,
	isOpen = true,
}: {
	children: React.ReactNode;
	isOpen?: boolean;
}) => {
	return (
		<Subscribe to={[BannerStateContainer]}>
			{(bannerState: BannerStateContainer) => {
				const headerHeight = bannerState.getTotalHeight(true);
				return (
					<SidePanel isFixed headerHeight={headerHeight} panelTop={headerHeight} isOpen={isOpen}>
						{children}
					</SidePanel>
				);
			}}
		</Subscribe>
	);
};
