import React from 'react';

import { AIEventsInstrumentationProvider } from '@atlassian/ai-analytics';

import { useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { BulkCreateDefaultView } from './BulkCreateDefaultView';
import { AiBulkNavigationContainer } from './AiBulkNavigationContainer';
import { BulkIssueCreateProvidersWrapper } from './providers/BulkIssueCreateProvidersWrapper';
import { useHasRequiredFields } from './utils/useHasRequiredFields';
import { IssueCreateWarningDialog } from './IssueCreateWarningDialog';
import { useCreateWarningDialogNav } from './utils/useCreateWarningDialogNav';
import { useGetInitialData } from './utils/useGetInitialData';

export const bulkCreateSidePanelTestId = 'bulk-issue-create-side-panel';
export const BULK_ISSUE_CREATE_SIDE_PANEL_ID = 'bulk-issue-create-side-panel';
export const BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH = 320;

export const BulkCreateSidePanelComponent = ({ onClose }: { onClose: () => void }) => {
	const [state] = useBulkCreateContext();
	const { isCreatedWithAi } = state;
	useHasRequiredFields();
	useGetInitialData();

	const { isWarningDialogOpen, handleConfirm, handleCancel } = useCreateWarningDialogNav(onClose);

	return (
		<>
			{isCreatedWithAi ? (
				<AiBulkNavigationContainer onClose={onClose} />
			) : (
				<BulkCreateDefaultView />
			)}
			{isWarningDialogOpen && (
				<IssueCreateWarningDialog onCancel={handleCancel} onConfirm={handleConfirm} />
			)}
		</>
	);
};

export const BulkCreateSidePanel = ({ onClose }: { onClose: () => void }) => {
	return (
		<BulkIssueCreateProvidersWrapper>
			<AIEventsInstrumentationProvider
				config={{
					// Required configruation
					product: 'confluence',
					subproduct: 'jira',
					aiFeatureName: 'confluenceAiWorkCreation',
					proactiveGeneratedAI: 0,
					userGeneratedAI: 1,
					// Optional configuration
					source: 'confluenceHighlightIssueCreate',
					skipAISessionValidation: true,
				}}
			>
				<BulkCreateSidePanelComponent onClose={onClose} />
			</AIEventsInstrumentationProvider>
		</BulkIssueCreateProvidersWrapper>
	);
};
