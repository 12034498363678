import React from 'react';

import { Flex, Box, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import { IconButton } from '@atlaskit/button/new';

const headerMessageStyle = xcss({
	paddingInline: 'space.100',
	font: 'font.heading.small',
	fontWeight: 'font.weight.medium',
	fontFamily: 'font.family.body',
	paddingBottom: 'space.025',
});

export const BulkCreateNavigationHeader = ({
	children,
	onClose,
}: {
	children: React.ReactNode;
	onClose: () => void;
}) => {
	return (
		<Box paddingBlockEnd="space.100">
			<Flex direction="row" alignItems="center">
				<IconButton
					icon={() => <ArrowLeftIcon label="back-icon" size="medium" />}
					appearance="subtle"
					label="close"
					onClick={onClose}
					testId="close-bulk-issue-create-panel-button"
				/>
				<Heading size="medium" as="h2">
					<Box xcss={headerMessageStyle}>{children}</Box>
				</Heading>
			</Flex>
		</Box>
	);
};
