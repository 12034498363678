import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Form from '@atlaskit/form';
import { Box, Stack, Text } from '@atlaskit/primitives';
import type { SingleValue } from '@atlaskit/react-select';

import type { AvailableSite } from '@atlassian/gic-anywhere';
import { useGetFirstAvailableSiteDomain } from '@atlassian/gic-anywhere';

import { useSessionData } from '@confluence/session-data';

import {
	useAiBulkNavigationCurrentPage,
	useAiBulkNavigationGotoPage,
} from './providers/AiBulkNavigationContextProvider';
import { BulkConfigureFooter } from './BulkConfigureFooter';
import type { IssueType } from './providers/BulkCreateContextProvider';
import { useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { BulkCreateNavigationHeader } from './BulkCreateNavigationHeader';
import type { JiraProject, JiraProjects } from './__types__/apiUtils';
import { messages } from './messages';
import { useGetIssueTypes } from './utils/useGetIssueTypes';
import { useGetJiraProjects } from './utils/useGetJiraProjects';
import type { Option } from './__types__/BulkConfigureTypes';
import { SiteSelector } from './SiteSelector';
import { ProjectSelector } from './ProjectSelector';
import { DefaultIssueTypeSelector } from './DefaultIssueTypeSelector';
import { SidePanelWrapper } from './SidePanelWrapper';

// This is the view for both AI and non-AI bulk create where users can edit and create individual issues
export const BulkConfigureView = () => {
	const [
		{ currentJiraSite, currentJiraProject, currentDefaultIssueType },
		{ setCurrentJiraSite, setCurrentJiraProject, setCurrentDefaultIssueType },
	] = useBulkCreateContext();
	const { cloudId } = useSessionData();

	const [selectedSite, setSelectedSite] = useState(currentJiraSite);
	const [selectedProject, setSelectedProject] = useState(currentJiraProject);
	const [selectedDefaultIssueType, setSelectedDefaultIssueType] = useState(currentDefaultIssueType);
	const [projectSelectorQuery, setProjectSelectorQuery] = useState<string>('');

	const debounceTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (currentJiraSite) {
			setSelectedSite(currentJiraSite);
		}
	}, [currentJiraSite]);

	useEffect(() => {
		if (currentJiraProject) {
			setSelectedProject(currentJiraProject);
		}
	}, [currentJiraProject]);

	useEffect(() => {
		if (currentDefaultIssueType) {
			setSelectedDefaultIssueType(currentDefaultIssueType);
		}
	}, [currentDefaultIssueType]);

	const { availableSites, loading: loadingSites } = useGetFirstAvailableSiteDomain({
		preferredCloudId: cloudId,
	});
	const {
		recentProjects,
		projects,
		loading: loadingProject,
	} = useGetJiraProjects({
		cloudId: selectedSite?.cloudId,
		query: projectSelectorQuery,
		onComplete: useCallback(
			(recentProjectData?: JiraProjects, projectData?: JiraProjects) => {
				// Automatically select the first recent project or first project if none selected
				if (!selectedProject) {
					if (recentProjectData && recentProjectData.length > 0) {
						setSelectedProject(recentProjectData[0]);
					} else if (projectData && projectData.length > 0) {
						setSelectedProject(projectData[0]);
					}
				}
			},
			[selectedProject],
		),
	});
	const { issueTypes, loading: loadingIssueTypes } = useGetIssueTypes({
		cloudId: selectedSite?.cloudId,
		projectId: selectedProject?.id,
		onComplete: useCallback(
			(data?: IssueType[]) => {
				// Automatically select the first issue type if none selected
				if (!selectedDefaultIssueType && data && data.length > 0) {
					void setCurrentDefaultIssueType(data[0]);
					setSelectedDefaultIssueType(data[0]);
				}
			},
			[selectedDefaultIssueType, setSelectedDefaultIssueType, setCurrentDefaultIssueType],
		),
	});

	const goToPage = useAiBulkNavigationGotoPage();
	const currentPage = useAiBulkNavigationCurrentPage();

	const isOpen = currentPage === 'configure';

	const handleSiteChange = useCallback((selectedSiteOption: SingleValue<Option>) => {
		setSelectedSite(selectedSiteOption?.value as AvailableSite);
		setSelectedProject(undefined);
		setSelectedDefaultIssueType(undefined);
	}, []);

	const handleProjectChange = useCallback((selectedProjectOption: SingleValue<Option>) => {
		setSelectedProject(selectedProjectOption?.value as JiraProject);
		setSelectedDefaultIssueType(undefined);
	}, []);

	const handleDefaultIssueTypeChange = useCallback(
		(selectedIssueTypeOption: SingleValue<Option>) => {
			setSelectedDefaultIssueType(selectedIssueTypeOption?.value as IssueType);
		},
		[],
	);

	const onProjectSelectorInputChange = (query: string) => {
		if (debounceTimeoutRef.current) {
			clearTimeout(debounceTimeoutRef.current);
		}
		debounceTimeoutRef.current = setTimeout(() => {
			setProjectSelectorQuery(query);
		}, 1000);
	};

	const onClose = useCallback(() => {
		setSelectedSite(currentJiraSite);
		setSelectedProject(currentJiraProject);
		setSelectedDefaultIssueType(currentDefaultIssueType);
		void goToPage('default');
	}, [goToPage, currentJiraSite, currentJiraProject, currentDefaultIssueType]);

	const onSubmit = useCallback(() => {
		void setCurrentJiraSite(selectedSite);
		void setCurrentJiraProject(selectedProject);
		void setCurrentDefaultIssueType(selectedDefaultIssueType);
		void goToPage('default');
	}, [
		goToPage,
		setCurrentJiraSite,
		setCurrentJiraProject,
		setCurrentDefaultIssueType,
		selectedSite,
		selectedProject,
		selectedDefaultIssueType,
	]);

	return (
		<SidePanelWrapper isOpen={isOpen}>
			<Box padding="space.200">
				<Stack space="space.100">
					<BulkCreateNavigationHeader onClose={onClose}>
						<FormattedMessage {...messages.bulkConfigureHeaderText} />
					</BulkCreateNavigationHeader>
					<Text color="color.text.subtlest">
						<FormattedMessage {...messages.bulkCreateConfigureBodyText} />
					</Text>
					<Form onSubmit={onSubmit}>
						{({ formProps }) => (
							<form id="configureForm" {...formProps}>
								<SiteSelector
									defaultValue={currentJiraSite}
									value={selectedSite}
									values={availableSites}
									onChange={handleSiteChange}
									isLoading={loadingSites}
									isDisabled={loadingSites}
								/>
								<ProjectSelector
									value={selectedProject}
									projects={projects}
									recentProjects={recentProjects}
									isLoading={loadingProject}
									onChange={handleProjectChange}
									onInputChange={onProjectSelectorInputChange}
								/>
								<DefaultIssueTypeSelector
									value={selectedDefaultIssueType}
									values={issueTypes}
									isLoading={loadingIssueTypes}
									isDisabled={loadingProject || loadingIssueTypes}
									onChange={handleDefaultIssueTypeChange}
								/>
							</form>
						)}
					</Form>
				</Stack>
			</Box>
			<BulkConfigureFooter
				onClose={onClose}
				isSaveDisabled={loadingSites || loadingProject || loadingIssueTypes}
			/>
		</SidePanelWrapper>
	);
};
