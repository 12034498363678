import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { Location } from 'history';

import { useSidePanelStateActions } from '@confluence/side-panel-state-container';

import {
	useIsWarningDialogOpen,
	useWarningDialogStateActions,
} from '../providers/IssueCreateWarningDialogContextProvider';

export const useCreateWarningDialogNav = (onClose: () => void) => {
	const { hideSidePanel } = useSidePanelStateActions();
	const { showWarningDialog, hideWarningDialog } = useWarningDialogStateActions();
	const isWarningDialogOpen = useIsWarningDialogOpen();
	const [nextLocation, setNextLocation] = useState<Location>();
	const history = useHistory();

	const handleConfirm = useCallback(() => {
		hideWarningDialog();
		if (nextLocation) {
			history.block(() => {});
			history.push(nextLocation.pathname);
		}
		hideSidePanel();
		onClose();
	}, [hideSidePanel, hideWarningDialog, history, nextLocation, onClose]);

	const handleCancel = useCallback(() => {
		hideWarningDialog();
	}, [hideWarningDialog]);

	useEffect(() => {
		const unblockNavigation = history.block((location) => {
			setNextLocation(location);
			showWarningDialog();
			return false;
		});

		return () => unblockNavigation && unblockNavigation();
	}, [history, showWarningDialog]);

	return {
		isWarningDialogOpen,
		handleConfirm,
		handleCancel,
	};
};
