import React from 'react';

export const AiErrorImage = () => (
	<svg width="118" height="97" viewBox="0 0 118 97" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1257_61439)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.33301 28.3123V96.881L25.1914 79.8548H84.7999V28.3123H5.33301Z"
				fill="#BF63F3"
			/>
			<path
				d="M40.6277 30.3495C40.6277 25.4304 44.553 21.4886 49.4059 21.4886C54.2587 21.4886 58.184 25.4139 58.184 30.3495C58.184 32.8505 57.5877 33.9933 57.0412 34.722C56.3952 35.5667 55.5837 36.1629 54.1593 37.2064C53.9274 37.3886 53.6624 37.5708 53.3809 37.7695C51.6087 39.0779 49.3562 40.8667 47.6668 43.8314C45.9609 46.8126 45.0168 50.5558 45.0168 55.5079H53.7949C53.7949 51.6655 54.5071 49.5289 55.269 48.2205C56.0309 46.8789 57.0743 45.968 58.598 44.8417C58.7802 44.7092 58.979 44.5601 59.1943 44.4111C60.569 43.4173 62.5234 42.0095 63.9974 40.0717C65.9021 37.5873 66.9621 34.4736 66.9621 30.3495C66.9621 20.6439 59.1777 12.7104 49.4059 12.7104C39.634 12.7104 31.8496 20.6439 31.8496 30.3495V34.7386H40.6277V30.3495Z"
				fill="#1868DB"
			/>
			<path
				d="M49.406 62.1165C46.3751 62.1165 43.9238 64.5677 43.9238 67.5986C43.9238 70.6296 46.3751 73.0808 49.406 73.0808C52.437 73.0808 54.8882 70.6296 54.8882 67.5986C54.8882 64.5677 52.437 62.1165 49.406 62.1165Z"
				fill="#1868DB"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M31.9489 28.3123H40.8596C40.6939 28.9582 40.6111 29.6538 40.6111 30.3494V34.7385H31.833V30.3494C31.833 29.6704 31.8661 28.9913 31.9489 28.3123Z"
				fill="#09326C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M54.8882 67.5984C54.8882 70.6293 52.4369 73.0972 49.406 73.0972C46.375 73.0972 43.9072 70.6293 43.9072 67.5984C43.9072 64.5675 46.375 62.1162 49.406 62.1162C52.4369 62.1162 54.8882 64.5675 54.8882 67.5984Z"
				fill="#09326C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M66.9619 30.3494C66.9619 34.4901 65.9019 37.5873 63.9972 40.0716C62.5232 42.0094 60.5688 43.4173 59.1941 44.411C58.9788 44.5766 58.78 44.7091 58.5979 44.8416C57.0741 45.9844 56.0307 46.8954 55.2688 48.2369C54.5069 49.5619 53.7947 51.6985 53.7947 55.5244H45.0166C45.0166 50.5723 45.9441 46.8291 47.65 43.8644C49.356 40.8998 51.6085 39.111 53.3641 37.786C53.6457 37.5873 53.9107 37.4051 54.1425 37.2229C55.5835 36.1629 56.3785 35.5832 57.0244 34.7385C57.5875 33.9932 58.1838 32.8504 58.1838 30.3494C58.1838 29.6538 58.101 28.9582 57.9354 28.3123H66.846C66.9288 28.9913 66.9619 29.6704 66.9619 30.3494Z"
				fill="#09326C"
			/>
			<path
				d="M80.0122 22.0463C79.6799 22.0789 77.8184 13.4596 78.1559 9.83758C76.9474 9.23678 75.7989 7.52436 75.6751 5.62882C70.5183 2.41068 74.3806 11.5603 75.6468 14.3967C76.2719 12.3407 76.09 17.3947 76.7845 18.0977C76.7063 17.6783 78.3602 26.0497 79.7136 23.5492C79.9391 23.7906 80.3949 21.7647 80.0181 22.0492L80.0122 22.0463Z"
				fill="#101214"
			/>
			<path
				d="M97.9714 11.2108C97.4794 9.37567 98.1356 7.71565 99.2924 6.08381C97.9034 -0.291475 93.8658 10.4331 92.9202 13.0451C92.9202 13.0451 91.164 17.598 91.09 17.0666C90.2879 17.4821 86.1799 23.9708 89.259 23.5153C90.0562 22.4885 90.6584 20.094 91.6197 20.0239C93.7172 19.3392 95.6045 13.1619 97.9685 11.2017L97.9714 11.2108Z"
				fill="#101214"
			/>
			<path
				d="M114.75 26.5761C115.405 22.4193 108.013 26.2102 105.02 26.6553C105.02 26.6553 101.899 26.945 101.091 27.5785C101.545 27.5379 90.5256 29.2208 96.531 30.806C96.6829 30.737 100.499 30.1605 100.499 30.1605C105.624 30.8975 110.77 28.8288 114.753 26.57L114.75 26.5761Z"
				fill="#101214"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1257_61439">
				<rect width="117" height="97" fill="white" transform="translate(0.5)" />
			</clipPath>
		</defs>
	</svg>
);
