/**
 * This file was cloned from https://bitbucket.org/atlassian/pf-adf-service/src/master/src/lib/converters/adf-to-markdownPlus.ts and slightly modified to work in the browser.
 */
import { type DocNode } from '@atlaskit/adf-schema';
import {
	type JSONDocNode,
	type JSONNode,
	JSONTransformer,
} from '@atlaskit/editor-json-transformer';
import {
	convertProsemirrorNodeToMarkdown,
	type FeatureToggles,
	type IDMap,
	type MentionMap,
} from '@atlassian/ai-model-io/convert-prosemirror-to-markdown';

const serializer = new JSONTransformer();

/**
 * This function converts ADF (aka ProsemirrorNode) to markdown-plus.
 *
 * @param adf the adf to convert to markdown
 * @param featureToggles [optional] choose which features to enable / disable
 * @param idMap [optional] a map of ids to avoid duplication
 * @returns markdown and an idMap for markdown-plus with custom tags
 */
export function adfToMarkdownPlus(
	adf: DocNode,
	featureToggles?: FeatureToggles,
	idMap?: IDMap,
): { markdown: string; idMap: IDMap } {
	// Default to markdownPlus being enabled unless explicitly disabled
	if (featureToggles === undefined) {
		featureToggles = {};
	}
	if (featureToggles?.markdownPlus === undefined) {
		featureToggles.markdownPlus = true;
	}
	if (featureToggles?.markdownPlusAvoidDuplicatedIdCounter === undefined) {
		featureToggles.markdownPlusAvoidDuplicatedIdCounter = true;
	}

	// Convert ADF to ProseMirrorNode
	const proseMirrorNode = serializer.parse(adf);

	// Generate the mentionMap required for correctly returning @mention strings in Markdown+
	const mentionMap = getMentionMap(serializer.encode(proseMirrorNode));

	// Convert ProseMirrorNode to markdown-plus
	const { markdown, idMap: newIdMap } = convertProsemirrorNodeToMarkdown({
		node: proseMirrorNode,
		featureToggles,
		mentionMap,
		idMap,
	});
	return { markdown, idMap: newIdMap };
}

function getMentionMap(jsonNode: JSONDocNode): MentionMap {
	const mentionMap: MentionMap = {};

	function findMentions(node: JSONNode) {
		if (node.type === 'mention') {
			const userValues = getUserMentionValues(node);
			if (userValues) {
				// Stripping '@' from the mention as Markdown+ automatically adds it back for each map value
				mentionMap[userValues.id] = userValues.text.replace('@', '');
			}
		}

		node.content?.forEach((childNode) => {
			if (childNode) {
				findMentions(childNode);
			}
		});
	}

	findMentions(jsonNode);
	return mentionMap;
}

function getUserMentionValues(node: JSONNode): { text: string; id: string } | null {
	if (node.attrs && hasTextAndIdFields(node.attrs)) {
		return {
			text: node.attrs.text,
			id: node.attrs.id,
		};
	}
	return null;
}

function hasTextAndIdFields(attrs: object): attrs is { text: string; id: string } {
	return (
		'text' in attrs &&
		'id' in attrs &&
		typeof attrs.text === 'string' &&
		typeof attrs.id === 'string'
	);
}
