import React, { useCallback } from 'react';

import { Inline } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';

import { useSetErrorMessage } from './providers/AiBulkNavigationContextProvider';
import { useCreateAllIssues } from './utils/useCreateAllIssues';
import { useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { useBulkCreateFlags } from './utils/useBulkCreateFlags';
import { useBulkCreateLoggingEvents } from './utils/useBulkCreateLoggingEvents';
import { FormattedMessage } from 'react-intl-next';
import { messages } from './messages';

// Bulk create button group - used for both AI and non-AI bulk create
export const BulkCreateButtonGroup = ({ onClose }: { onClose: () => void }) => {
	const { createAllIssuesHandler, loading: isCreateAllLoading } = useCreateAllIssues();
	const [{ aiBulkIssuesList, issueTypeHasRequiredFields }, { removeAiBulkIssueList }] =
		useBulkCreateContext();
	const { triggerMultipleIssuesCreatedFlag } = useBulkCreateFlags();
	const setErrorMessage = useSetErrorMessage();
	const { logCreateAllClick, logMultipleIssueCreateSuccess, logAiIssueCreationFailed } =
		useBulkCreateLoggingEvents();

	const onCreateAllClick = useCallback(() => {
		logCreateAllClick();
		setErrorMessage(null);
		void createAllIssuesHandler({
			onComplete: ({ createdIssues, createdIssueIDs, failedIssueIDs }) => {
				logMultipleIssueCreateSuccess(createdIssueIDs.length);
				void removeAiBulkIssueList(createdIssueIDs);
				triggerMultipleIssuesCreatedFlag(createdIssues);
				if (failedIssueIDs.length !== 0) {
					logAiIssueCreationFailed(failedIssueIDs.length);
					void setErrorMessage({
						createdCount: createdIssueIDs.length,
						failedCount: failedIssueIDs.length,
					});
				}
			},
			onFailure: (error: Error | unknown) => {
				logAiIssueCreationFailed(aiBulkIssuesList.length, error);
				void setErrorMessage({ createdCount: 0, failedCount: aiBulkIssuesList.length });
			},
		});
	}, [
		removeAiBulkIssueList,
		createAllIssuesHandler,
		triggerMultipleIssuesCreatedFlag,
		setErrorMessage,
		aiBulkIssuesList,
		logCreateAllClick,
		logMultipleIssueCreateSuccess,
		logAiIssueCreationFailed,
	]);

	return (
		<Inline>
			<Button onClick={onClose} testId="bulk-create-button-group-cancel">
				<FormattedMessage {...messages.bulkCreateCancelFooterButton} />
			</Button>
			<Button
				appearance="primary"
				isDisabled={issueTypeHasRequiredFields}
				isLoading={isCreateAllLoading}
				onClick={onCreateAllClick}
				testId="bulk-create-button-group-create-all"
			>
				<FormattedMessage {...messages.bulkCreateCreateAllFooterButton} />
			</Button>
		</Inline>
	);
};
