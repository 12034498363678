import {
	createStore,
	createStateHook,
	createActionsHook,
	createContainer,
	type StoreActionApi,
} from 'react-sweet-state';

import type { FlagsStateContainer } from '@confluence/flags';

import type { BulkCreateErrorType } from '../BulkCreateErrorSectionMessage';

export type AiBulkNavigationPageType = 'default' | 'configure' | 'edit_issue';

type NavigationState = {
	currentPage: AiBulkNavigationPageType;
	flags?: FlagsStateContainer;
	errorMessage: BulkCreateErrorType;
};

const initialState: NavigationState = {
	currentPage: 'default',
	errorMessage: null,
};

const actions = {
	goToPage:
		(page: AiBulkNavigationPageType) =>
		({ setState }: StoreActionApi<NavigationState>) => {
			setState({ currentPage: page });
		},
	setFlags:
		(flags: FlagsStateContainer) =>
		({ setState }: StoreActionApi<NavigationState>) => {
			setState({ flags });
		},
	setErrorMessage:
		(errorMessage: BulkCreateErrorType) =>
		({ setState }: StoreActionApi<NavigationState>) => {
			setState({ errorMessage });
		},
};

const Store = createStore<NavigationState, typeof actions>({
	initialState,
	actions,
	name: 'AiBulkNavigationState',
});

export const AiBulkNavigationContextContainer = createContainer(Store);

const useActions = createActionsHook(Store);

export const useAiBulkNavigationGotoPage = () => {
	const { goToPage } = useActions();
	return goToPage;
};

export const useAiBulkNavigationCurrentPage = createStateHook(Store, {
	selector: (state: NavigationState) => state.currentPage,
});

export const useSetBulkCreateFlagsContainer = () => {
	const { setFlags } = useActions();
	return setFlags;
};

export const useBulkCreateFlagsContainer = createStateHook(Store, {
	selector: (state: NavigationState) => state.flags,
});

export const useSetErrorMessage = () => {
	const { setErrorMessage } = useActions();
	return setErrorMessage;
};

export const useErrorMessage = createStateHook(Store, {
	selector: (state: NavigationState) => state.errorMessage,
});
