import React from 'react';

import { BulkEditIssueView } from './BulkEditIssueView';
import { AiBulkDefaultView } from './AiBulkDefaultView';
import { BulkConfigureView } from './BulkConfigureView';
import { useAiBulkFetchIssuesList } from './utils/useAiBulkFetchIssuesList';
import { BulkCreateFlagsContainer } from './BulkCreateFlagsContainer';

export const AiBulkNavigationContainer = ({ onClose }: { onClose: () => void }) => {
	useAiBulkFetchIssuesList();

	return (
		<>
			<AiBulkDefaultView onClose={onClose} />
			<BulkConfigureView />
			<BulkEditIssueView />
			<BulkCreateFlagsContainer />
		</>
	);
};
