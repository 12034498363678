import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	iFrameLoading: {
		id: 'jira-ai.gic-anywhere.iframe-loading',
		defaultMessage: 'Loading issue details, this might take a few seconds.',
		description: 'Content to show while waiting from GIC iframe to load.',
	},
	iFrameSlowLoading: {
		id: 'jira-ai.gic-anywhere.iframe-slow-loading',
		defaultMessage:
			'It is taking longer than usual to load your issue.{br}Give it a few more seconds, or try again later.',
		description: 'Content to show when GIC iframe is slow to load.',
	},
	openInJiraButton: {
		id: 'jira-ai.gic-anywhere.open-in-jira-button',
		defaultMessage: 'Log in to Jira to view',
		description: 'Button message to display for error states. Clicking opens Jira in a new tab.',
	},
	errorHeader: {
		id: 'jira-ai.gic-anywhere.error-header',
		defaultMessage: "Something's gone wrong",
		description: 'Content to show for error header if GIC does not load.',
	},
	errorBody: {
		id: 'jira-ai.gic-anywhere.error-body',
		defaultMessage:
			'Try opening this in Jira. If the problem persists, please contact Atlassian support.',
		description: 'Content to show for error body if GIC does not load.',
	},
});
